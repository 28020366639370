<template>
    <div class="login_container">
        <div class="login_box">
            <!--头像-->
            <div class="avatar_box">
                <img src="../assets/logo.png" alt=""/>
            </div>
             <!--登录表单-->
            <!--
              :model绑定数据
              :rules绑定规则
            -->
            <el-form ref="loginFormRef"  label-width="0px" class="login_form" :model="form" :rules="rules">
            <!--prop应用哪个验证 -->
                <el-form-item prop="account">
                    <el-input prefix-icon="el-icon-user-solid" v-model="form.account"></el-input>
                </el-form-item>
                <el-form-item  prop="pw">
                    <el-input prefix-icon="el-icon-lock" type="password" v-model="form.pw"></el-input>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button type="primary" @click="loginCheck">登录</el-button>
                    <el-button type="info" @click="resetForm">重置</el-button>
                </el-form-item>
             </el-form>

        </div>
    </div>
</template>

<script>
export default {
    data:function(){
        return {
            form:{
                 account:"13520790602",
                 pw:"13520790602"
            },
            rules:{
                //验证用户名
                account:[
                    {required:true,message:"请输入用户名",trigger:'blur'}
                ],
                //验证密码
                pw:[
                    {min:3,max:12,message:"长度在3-12个字符之间",trigger:'blur'}
                ]
            }
        }
    },
  methods:{
    //重置功能
    resetForm:function(){
       this.$refs.loginFormRef.resetFields();
    },
    //登录预验证
    loginCheck:function(){
      this.$refs.loginFormRef.validate(async (valid)=>{
         //没成功
         if(!valid){
              return false;
          }else{
             const {data:res} = await this.$http.post('login/index',this.form);
             if(res.code!==0){
               return this.$message({message:"登录失败",type:'error'});
             }
              sessionStorage.setItem("access_token",res.data.access_token);
              sessionStorage.setItem("refresh_token",res.data.refresh_token);
              this.$message({message:"登录成功",type:'success'});
              //跳转
              this.$router.push("/home");
          }
      });
    }
  }
}
</script>

<style scoped>
/*lang less支持语法，scoped 影响范围，只在本结构有效*/
  .login_container{
       background: #2b4b6b;
       height: 100%;
  }
.login_box{
    width: 450px;
    height: 300px;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
}

.login_box .avatar_box{
    height: 130px;
    width: 130px;
    border:1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow:0 10px 10px #eee ;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
}

.login_box .avatar_box img{
   width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #eee;
}
.btns{
    display: flex;
    /*定义项目在主轴上的对齐方式*/
    justify-content: flex-end;
}
.login_form{
    position: absolute;
    bottom:0px;
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
}
</style>
